import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const ChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      d="m19 8.5-7 7-7-7"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ChevronDown;
