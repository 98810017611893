import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const Filter = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.117 17.987H2.883"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21.118 17.987a2.88 2.88 0 1 1-5.76 0 2.88 2.88 0 0 1 5.76 0Z"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.883 6.262h7.235"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.883 6.262a2.88 2.88 0 1 0 5.76 0 2.88 2.88 0 1 0-5.76 0Z"
        clipRule="evenodd"
      />
  </SvgIcon>
);

export default Filter;
