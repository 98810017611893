import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const Gift = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M16.742 22H7.258A4.256 4.256 0 0 1 3 17.745V12.23a4.256 4.256 0 0 1 4.258-4.255h9.484A4.256 4.256 0 0 1 21 12.23v5.516A4.256 4.256 0 0 1 16.742 22Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path stroke="currentColor" strokeWidth={1.5} d="M3 15.25h18" />
    <path
      d="M12 5a3 3 0 1 0-3 3M15 8a3 3 0 1 0-3-3M12 5v17"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Gift;
