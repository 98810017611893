import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const Chrono = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M21.25 13A9.25 9.25 0 0 1 12 22.25 9.25 9.25 0 0 1 2.75 13 9.25 9.25 0 0 1 12 3.75 9.25 9.25 0 0 1 21.25 13Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m15.432 15.943-3.77-2.25V8.848"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path stroke="currentColor" strokeLinecap="round" d="M8.5 1.5h7" />
  </SvgIcon>
);

export default Chrono;
