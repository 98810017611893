import { ChangeEvent, FC } from 'react';

interface Props<InputT> {
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string | React.ReactNode;
  name?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  size?: 'sm' | 'md' | 'lg';
  value?: InputT;
  checked: boolean;
  reverse?: boolean;
  id?: string;
}

const Checkbox: FC<Props<any>> = (props) => {
  return (
    <div className={props.className}>
      <label
        className={`tw-flex tw-items-start tw-m-0 tw-gap-2 tw-cursor-pointer ${
          props.reverse ? 'tw-flex-row-reverse' : 'tw-flex-row'
        }`}
      >
        <input
          id={props.id}
          checked={props.checked}
          className={[
            'tw-mt-1 tw-form-checkbox tw-rounded tw-border-gray-300 tw-text-primary tw-shadow-sm focus:tw-border-gray-300 focus:tw-ring focus:tw-ring-offset-0 focus:tw-ring-gray-200 focus:tw-ring-opacity-50 disabled:tw-text-indigo-300 disabled:tw-cursor-not-allowed disabled:tw-border-gray-200 disabled:tw-shadow-none',
            props.size === 'lg'
              ? 'tw-h-6 tw-w-6'
              : props.size === 'md'
              ? 'tw-h-5 tw-w-5'
              : 'tw-h-4 tw-w-4',
          ].join(' ')}
          disabled={props.disabled}
          name={props.name}
          type="checkbox"
          value={props.value}
          onChange={props.onChange}
        />
        {!!props.label && (
          <span
            className={[
              props.labelClassName || {},
              'tw-flex-1 tw-text-gray-700 tw-text-base',
            ].join(' ')}
          >
            {props.label}
          </span>
        )}
      </label>
      {!!props.errorMessage && (
        <span className="tw-text-red-500 tw-text-xs">{props.errorMessage}</span>
      )}
    </div>
  );
};

export default Checkbox;
