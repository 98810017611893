import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const UserGroup = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      d="M16.02 10.913a3.297 3.297 0 1 0 0-6.594M17.536 14.496a10.9 10.9 0 0 1 1.617.233c.74.147 1.63.45 1.945 1.113.202.425.202.92 0 1.345-.315.663-1.206.966-1.945 1.118"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M9.591 15.206c3.69 0 6.842.56 6.842 2.792 0 2.234-3.131 2.812-6.842 2.812-3.69 0-6.84-.558-6.84-2.792s3.13-2.812 6.84-2.812ZM9.592 12.019a4.369 4.369 0 0 1-4.384-4.385A4.368 4.368 0 0 1 9.592 3.25a4.37 4.37 0 0 1 4.384 4.384 4.37 4.37 0 0 1-4.384 4.385Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default UserGroup;
