'use client';

import { useState } from 'react';

export default function useToggle(initialValue = false) {
  const [isOpen, setIsOpen] = useState(initialValue);

  const createOpenCloseHandler = (willOpen: boolean) => () => {
    setIsOpen(willOpen);
  };

  return {
    isOpen,
    open: createOpenCloseHandler(true),
    close: createOpenCloseHandler(false),
  };
}
