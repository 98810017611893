import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const ChevronRight = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      d="m8.5 5 7 7-7 7"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ChevronRight;
