import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const Check = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.695 13.39a1.17 1.17 0 0 1-1.168-1.168 1.169 1.169 0 0 1 2.337 0 1.17 1.17 0 0 1-1.169 1.169Zm4.543 0a1.17 1.17 0 0 1-1.168-1.168 1.169 1.169 0 0 1 2.337 0 1.17 1.17 0 0 1-1.169 1.169Zm3.374-1.168a1.17 1.17 0 0 0 2.338 0 1.169 1.169 0 0 0-2.338 0Z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M12.02 2C6.21 2 2 6.746 2 12.015c0 1.682.49 3.414 1.35 4.997.16.26.18.59.07.902l-.67 2.243c-.15.541.31.94.82.78l2.02-.6c.55-.18.98.05 1.49.361C8.54 21.558 10.36 22 12 22c4.96 0 10-3.836 10-10.015C22 6.656 17.7 2 12.02 2Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Check;
