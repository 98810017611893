'use client';

import { useEffect, useState } from 'react';

type CopyStatus = 'copy' | 'copied';

interface Args {
  resetStatusTimeout?: number;
}

export default function useCopyToClipboard({
  resetStatusTimeout = 2000,
}: Args = {}) {
  const [status, setStatus] = useState<CopyStatus>('copy');

  useEffect(() => {
    if (status !== 'copied') {
      return;
    }
    const timer = setTimeout(() => {
      setStatus('copy');
    }, resetStatusTimeout);
    return () => clearTimeout(timer);
  }, [status]);

  function copy(textToCopy: string) {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(textToCopy);
    } else {
      document.execCommand('copy', true, textToCopy);
    }
    setStatus('copied');
  }

  return { status, copy };
}
