import { ReactElement } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

export interface Props<T> {
  className?: string;
  data: ReadonlyArray<T>;
  keyExtractor?: (item: T, index: number) => string;
  loading?: boolean;
  loadingItemsCount?: number;
  hasNextPage?: boolean;
  onLoadMore?: () => void;
  renderItem: (item: T, index?: number) => ReactElement;
  renderLoadingItem?: (index?: number) => ReactElement;
}

export default function List<T extends unknown>({
  className = '',
  loading = false,
  loadingItemsCount = 16,
  hasNextPage = false,
  ...props
}: Props<T>) {
  const [infiniteRef] = useInfiniteScroll({
    loading,
    disabled: !props.onLoadMore,
    hasNextPage,
    onLoadMore: () => {
      if (props.onLoadMore) {
        props.onLoadMore();
      }
    },
  });

  return (
    <div className={className}>
      {props.data.map((item, index) => (
        <div key={props.keyExtractor && props.keyExtractor(item, index)}>
          {props.renderItem(item, index)}
        </div>
      ))}
      {Array(loading ? loadingItemsCount : 0)
        .fill(0)
        .map((_, i) => (
          <div key={i}>
            {props.renderLoadingItem && props.renderLoadingItem(i)}
          </div>
        ))}
      {hasNextPage && <div ref={infiniteRef} />}
    </div>
  );
}
