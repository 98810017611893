import { FC, ReactNode } from 'react';

interface Props {
  active?: boolean;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
}

const Chip: FC<Props> = (props) => (
  <div
    className={`
      ${props.className || ''}
      tw-inline-flex tw-items-center tw-shrink-0 tw-rounded-full tw-border tw-px-2.5 tw-py-2 tw-text-sm tw-font-medium tw-tracking-tight tw-leading-4 tw-gap-2 tw-cursor-pointer hover:tw-text-opacity-100 focus:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-white focus-visible:tw-ring-opacity-75
      ${props.active ? 'tw-border-secondary-light tw-bg-primary/10 tw-text-secondary' : 'tw-border-greyscale-label tw-text-greyscale-label'}
    `}
    onClick={() => {
      if (props.onClick) {
        props.onClick();
      }
    }}
  >
    {props.children}
  </div>
);

export default Chip;
