import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const Game = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.848 12.314v3.745M10.76 14.187H6.937M15.366 12.428h-.107M17.18 16.003h-.107M8.072 2c0 .74.613 1.34 1.368 1.34h1.057c1.165.005 2.11.93 2.115 2.073v.675"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.428 21.962c-3.005.051-5.955.05-8.855 0-3.22 0-5.573-2.296-5.573-5.45v-4.65C2 8.707 4.354 6.41 7.573 6.41c2.916-.05 5.868-.049 8.855 0 3.22 0 5.572 2.298 5.572 5.452v4.65c0 3.154-2.352 5.45-5.572 5.45Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default Game;
