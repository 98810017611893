import { FC } from 'react';

interface Props<InputT> {
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  name?: string;
  onChange: (value: InputT) => void;
  size?: 'sm' | 'md' | 'lg';
  value: InputT;
  selectedValue: InputT;
  reverse?: boolean;
}

const Radio: FC<Props<any>> = (props) => {
  return (
    <div className={props.className}>
      <label
        className={`tw-flex tw-items-center tw-m-0 tw-gap-2 tw-cursor-pointer ${
          props.reverse ? 'tw-flex-row-reverse' : 'tw-flex-row'
        }`}
      >
        <input
          checked={props.value === props.selectedValue}
          className={[
            'tw-form-radio tw-rounded-full tw-border-greyscale-separator tw-text-primary tw-shadow-sm focus:tw-border-greyscale-separator focus:tw-ring focus:tw-ring-offset-0 focus:tw-ring-gray-200 focus:tw-ring-opacity-50 disabled:tw-text-gray-300 disabled:tw-cursor-not-allowed disabled:tw-border-gray-200 disabled:tw-shadow-none',
            props.size === 'lg'
              ? 'tw-h-6 tw-w-6'
              : props.size === 'md'
              ? 'tw-h-5 tw-w-5'
              : 'tw-h-4 tw-w-4',
          ].join(' ')}
          disabled={props.disabled}
          name={props.name}
          type="radio"
          value={props.value}
          onChange={() => props.onChange(props.value)}
        />
        {!!props.label && (
          <span className="tw-flex-1 tw-text-greyscale-label tw-text-base tw-font-medium tw-tracking-tight">
            {props.label}
          </span>
        )}
      </label>
      {!!props.errorMessage && (
        <span className="tw-text-red-500 tw-text-xs">{props.errorMessage}</span>
      )}
    </div>
  );
};

export default Radio;
