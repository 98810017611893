import { MouseEvent, PropsWithChildren, forwardRef } from 'react';
// import Lottie from 'lottie-react';

interface Props extends PropsWithChildren {
  id?: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  palette?: 'primary' | 'accent' | 'gray' | 'white';
  rounded?: boolean;
  theme?: 'primary' | 'outline' | 'ghost';
  type?: 'button' | 'link' | 'reset' | 'submit';
  size?: 'sm' | 'md' | 'lg';
  variant?: 'default' | 'block' | 'icon';
  target?: string;
  href?: string;
}

function Button(
  {
    id,
    disabled = false,
    loading = false,
    palette = 'primary',
    rounded = false,
    size = 'md',
    theme = 'primary',
    variant = 'default',
    ...props
  }: Props,
  ref,
) {
  const getColors = () => {
    if (palette === 'primary') {
      return theme === 'primary'
        ? 'tw-bg-gradient-to-r tw-from-primary tw-to-[#ef5989] tw-text-white hover:tw-text-white hover:tw-from-primary-dark hover:tw-to-primary-dark disabled:tw-from-secondary-light disabled:tw-to-secondary-light'
        : theme === 'outline'
        ? 'tw-bg-transparent tw-border tw-border-primary tw-text-primary hover:tw-bg-primary hover:tw-border-primary hover:tw-text-white disabled:tw-border-secondary-light disabled:tw-text-secondary-light'
        : 'tw-bg-transparent tw-text-primary hover:tw-bg-primary-dark hover:tw-text-white disabled:tw-text-secondary-light';
    }
    if (palette === 'gray') {
      return theme === 'primary'
        ? 'tw-bg-gray-200 tw-text-gray-500 hover:tw-bg-gray-300 hover:tw-text-gray-500'
        : theme === 'outline'
        ? 'tw-bg-transparent tw-border tw-border-gray-600 tw-text-gray-600 hover:tw-bg-gray-300 hover:tw-border-gray-300 hover:tw-text-gray-600'
        : 'tw-bg-transparent tw-text-gray-600 hover:tw-bg-gray-300 hover:tw-text-gray-600';
    }
    if (palette === 'white') {
      return theme === 'primary'
        ? 'tw-bg-white tw-text-gray-500 hover:tw-bg-gray-300'
        : theme === 'outline'
        ? 'tw-bg-transparent tw-border tw-border-white tw-text-white hover:tw-bg-gray-300 hover:tw-border-gray-300 hover:tw-text-gray-600'
        : 'tw-bg-transparent tw-text-white hover:tw-bg-gray-300 hover:tw-text-gray-600';
    }
    if (palette === 'accent') {
      return theme === 'primary'
        ? 'tw-bg-accent tw-text-white hover:tw-bg-success-dark'
        : theme === 'outline'
        ? 'tw-bg-transparent tw-border tw-border-accent tw-text-accent hover:tw-bg-gray-300 hover:tw-border-accent-dark hover:tw-text-accent-dark'
        : 'tw-bg-transparent tw-text-accent hover:tw-bg-gray-300 hover:tw-text-accent-dark';
    }
  };

  const getDimensions = () => {
    if (variant === 'icon') {
      return size === 'lg'
        ? 'tw-w-12 tw-h-12'
        : size === 'sm'
        ? 'tw-w-8 tw-h-8'
        : 'tw-w-10 tw-h-10';
    }
    const value =
      size === 'lg'
        ? 'tw-p-3'
        : size === 'sm'
        ? 'tw-py-1 tw-px-3'
        : 'tw-py-2 tw-px-3';
    return `${variant === 'block' ? 'tw-w-full' : ''} ${value}`;
  };

  const getClass = () => {
    return [
      'tw-inline-flex tw-items-center tw-justify-center tw-gap-1 tw-text-base tw-font-semibold tw-tracking-tight tw-no-underline disabled:tw-cursor-not-allowed',
      rounded ? 'tw-rounded-full' : 'tw-rounded-lg',
      props.className,
      getColors(),
      getDimensions(),
    ].join(' ');
  };

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (loading || disabled) {
      return;
    }
    if (props.onClick) {
      props.onClick(e);
    }
  };

  const renderChildren = () => {
    return (
      <>
        {props.children} {/*{loading && (*/}
        {/*  <Lottie*/}
        {/*    className="tw-h-6 tw-w-6"*/}
        {/*    animationData={loadingAnimation}*/}
        {/*    autoPlay*/}
        {/*    loop*/}
        {/*  />*/}
        {/*)}*/}
      </>
    );
  };

  if (props.type === 'link') {
    return (
      <div {...props} className={getClass()}>
        {renderChildren()}
      </div>
    );
  }

  return (
    <button
      id={id}
      className={getClass()}
      disabled={disabled}
      onClick={handleClick}
      type={props.type}
    >
      {renderChildren()}
    </button>
  );
}

export default forwardRef(Button);
