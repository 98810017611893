import List, { Props as ListProps } from './List';
import { Breakpointable } from './types';

interface Props<T> extends ListProps<T> {
  columns?: Breakpointable;
  gap?: Breakpointable;
}

export default function Grid<T extends unknown>({
  className = '',
  columns = 1,
  gap = 8,
  ...props
}: Props<T>) {
  function getColumns() {
    if (!columns) {
      return '';
    }
    if (typeof columns === 'number') {
      return `tw-grid-cols-${columns}`;
    }
    return [
      columns.xs ? `tw-grid-cols-${columns.xs}` : '',
      columns.sm ? `sm:tw-grid-cols-${columns.sm}` : '',
      columns.md ? `md:tw-grid-cols-${columns.md}` : '',
      columns.lg ? `lg:tw-grid-cols-${columns.lg}` : '',
      columns.xl ? `xl:tw-grid-cols-${columns.xl}` : '',
      columns.xxl ? `2xl:tw-grid-cols-${columns.xxl}` : '',
    ].join(' ');
  }

  function getGap() {
    if (!gap) {
      return '';
    }
    if (typeof gap === 'number') {
      return `tw-gap-${gap}`;
    }
    return [
      gap.xs ? `tw-gap-${gap.xs}` : '',
      gap.sm ? `sm:tw-gap-${gap.sm}` : '',
      gap.md ? `md:tw-gap-${gap.md}` : '',
      gap.lg ? `lg:tw-gap-${gap.lg}` : '',
      gap.xl ? `xl:tw-gap-${gap.xl}` : '',
      gap.xxl ? `2xl:tw-gap-${gap.xxl}` : '',
    ].join(' ');
  }

  return (
    <List
      className={`tw-grid ${getGap()} ${getColumns()} ${className}`}
      {...props}
    />
  );
}
