import { ChangeEvent, FC } from 'react';

interface Props<InputT> {
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  onChange: (e: ChangeEvent<any>) => void;
  placeholder?: string;
  rows?: number;
  type?: string;
  value: InputT;
  name: string;
}

const TextArea: FC<Props<any>> = (
  props = {
    disabled: false,
    rows: 3,
    type: 'text',
    onChange: () => { return; },
    value: '',
    name: '',
  },
) => {
  return (
    <div className={props.className}>
      <label className="tw-block tw-m-0">
        {props.label && (
          <span className="tw-text-base tw-block tw-text-gray-700 tw-mb-1">
            {props.label}
          </span>
        )}
        <textarea
          className={[
            'tw-form-textarea tw-block tw-w-full tw-rounded-md tw-shadow-sm focus:tw-border-gray-300 focus:tw-ring focus:tw-ring-gray-200 focus:tw-ring-opacity-50 disabled:tw-bg-gray-100 disabled:tw-cursor-not-allowed disabled:tw-text-gray-500 disabled:tw-border-gray-200 disabled:tw-shadow-none',
            props.errorMessage
              ? 'tw-border-red-500 tw-bg-red-50'
              : 'tw-border-gray-300 tw-bg-white',
          ].join(' ')}
          value={props.value}
          name={props.name}
          onChange={(event) => props.onChange && props.onChange(event)}
          disabled={props.disabled}
          placeholder={props.placeholder}
          rows={props.rows}
        />
      </label>
      {props.errorMessage && (
        <span className="tw-text-red-500 tw-text-xs">{props.errorMessage}</span>
      )}
    </div>
  );
};
export default TextArea;
