import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const People = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      d="M17.595 10.9319C19.1973 10.9319 20.4971 9.63298 20.4971 8.03064C20.4971 6.42831 19.1973 5.12939 17.595 5.12939"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9292 14.0847C19.4077 14.1177 19.8834 14.1856 20.3519 14.291C21.0027 14.4184 21.7855 14.6851 22.0642 15.2691C22.242 15.6431 22.242 16.0785 22.0642 16.4534C21.7864 17.0373 21.0027 17.3031 20.3519 17.437"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.29011 10.9319C4.68777 10.9319 3.38794 9.63298 3.38794 8.03064C3.38794 6.42831 4.68777 5.12939 6.29011 5.12939"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.95589 14.0847C4.47739 14.1177 4.00164 14.1856 3.53322 14.291C2.88239 14.4184 2.09956 14.6851 1.82181 15.2691C1.64306 15.6431 1.64306 16.0785 1.82181 16.4534C2.09864 17.0373 2.88239 17.3031 3.53322 17.437"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9379 14.7095C15.1847 14.7095 17.9586 15.2008 17.9586 17.1671C17.9586 19.1324 15.2031 19.6421 11.9379 19.6421C8.69015 19.6421 5.91724 19.1507 5.91724 17.1845C5.91724 15.2182 8.67274 14.7095 11.9379 14.7095Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9378 11.9049C9.79651 11.9049 8.07959 10.188 8.07959 8.04575C8.07959 5.90442 9.79651 4.1875 11.9378 4.1875C14.0792 4.1875 15.7961 5.90442 15.7961 8.04575C15.7961 10.188 14.0792 11.9049 11.9378 11.9049Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default People;
