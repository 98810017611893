import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const ChevronUp = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      d="m5 15.5 7-7 7 7"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ChevronUp;
