import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const Minus = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      d="M5.6 12h14"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Minus;
