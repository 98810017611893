import { ChangeEvent, ReactElement, forwardRef } from 'react';

interface Props {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  max?: number | string | undefined;
  maxLength?: number | undefined;
  min?: number | string | undefined;
  minLength?: number | undefined;
  placeholder?: string;
  type?: string;
  value: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  bottomText?: string;
  id?: string;
  name?: string;
  onClick?: () => void;
}

export const TextInput = forwardRef<HTMLInputElement, Props>(
  (props = { value: '', disabled: false, type: 'text' }, ref) => {
    return (
      <div
        className={props.className}
        onClick={() => props.onClick && props.onClick()}
      >
        <label className="tw-block tw-m-0">
          {props.label && (
            <span className="tw-text-base tw-block tw-text-gray-700 tw-mb-1">
              {props.label}
            </span>
          )}
          <div className="tw-relative">
            {!!props.leftIcon && (
              <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3">
                {props.leftIcon}
              </div>
            )}
            <input
              ref={ref}
              id={props.id}
              name={props.name}
              autoFocus={props.autoFocus}
              className={[
                'tw-form-input tw-block tw-w-full tw-rounded-md tw-shadow-sm focus:tw-border-gray-300 focus:tw-ring focus:tw-ring-gray-200 focus:tw-ring-opacity-50 disabled:tw-bg-gray-100 disabled:tw-cursor-not-allowed disabled:tw-text-gray-500 disabled:tw-border-gray-200 disabled:tw-shadow-none',
                props.errorMessage
                  ? 'tw-border-red-500 tw-bg-red-50'
                  : 'tw-border-gray-300 tw-bg-white',
                !!props.leftIcon ? 'tw-pl-10' : '',
              ].join(' ')}
              value={props.value}
              disabled={props.disabled}
              type={props.type}
              placeholder={props.placeholder}
              max={props.max}
              maxLength={props.maxLength}
              min={props.min}
              minLength={props.minLength}
              onChange={(event) => props.onChange && props.onChange(event)}
              onFocus={(event) => props.onFocus && props.onFocus()}
              onBlur={(event) => props.onBlur && props.onBlur()}
            />
            {props.bottomText && (
              <div className="tw-text-xs tw-mt-1">{props.bottomText}</div>
            )}
          </div>
        </label>
        {props.errorMessage && (
          <span className="tw-text-red-500 tw-text-xs">
            {props.errorMessage}
          </span>
        )}
      </div>
    );
  },
);

export default TextInput;
