import { PropsWithChildren } from 'react';
import { Close as IconClose } from '../icons';
import { useToggle } from '../../hooks';

interface Props extends PropsWithChildren {
  className?: string;
  dismissable?: boolean;
  status?: 'error' | 'info' | 'success' | 'warning';
}

export default function Alert({
  children,
  className,
  dismissable,
  status = 'info',
}: Props) {
  const {isOpen, close} = useToggle(true);

  function getColors() {
    if (status === 'success') {
      return 'tw-bg-success-light tw-text-greyscale-body';
    }
    if (status === 'error') {
      return 'tw-bg-error tw-text-greyscale-offwhite';
    }
    if (status === 'warning') {
      return 'tw-bg-warning-light tw-text-warning-dark';
    }
    return 'tw-bg-greyscale-input tw-text-greyscale-label';
  }

  if (dismissable && !isOpen) {
    return null;
  }

  return (
    <div className={`tw-flex tw-items-start tw-justify-between tw-text-sm tw-font-medium tw-tracking-tight tw-px-6 tw-py-4 tw-rounded-lg ${getColors()} ${className}`}>
      <span>{children}</span>
      {dismissable && (
        <button
          className="tw-shrink-0 tw-inline-block"
          onClick={close}
          type="button"
        >
          <IconClose className="tw-h-5" />
        </button>
      )}
    </div>
  );
}
