import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const ChevronLeft = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      d="m15.5 19-7-7 7-7"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ChevronLeft;
