import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const Search = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      d="M10.578 2C5.84 2 2 5.882 2 10.67s3.84 8.67 8.578 8.67c4.737 0 8.577-3.882 8.577-8.67 0-2.3-.903-4.505-2.512-6.13A8.531 8.531 0 0 0 10.578 2Z"
      stroke="currentColor"
    />
    <path
      d="m21.568 19.716-2.555-2.062a.986.986 0 0 0-1.392 0 1.082 1.082 0 0 0 0 1.525l2.12 2.43a1.314 1.314 0 0 0 1.871 0 1.348 1.348 0 0 0 0-1.893h-.044Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Search;
