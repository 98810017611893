import { ReactElement } from 'react';
import { Link } from '../../navigation';

interface IconArgs {
  active?: boolean;
  className: string;
}

export interface Tab {
  active?: boolean;
  icon?: (args?: IconArgs) => ReactElement;
  label: string;
  url: {
    href: string;
    replace?: boolean;
  };
}

interface Props {
  items: Tab[];
}

const Tabs = ({ items }: Props) => {
  return (
    <nav className="tw-grid md:tw-hidden tw-grid-flow-col tw-fixed tw-inset-x-0 tw-bottom-0 tw-z-10 tw-border tw-border-gray-200 dark:tw-border-gray-700 tw-bg-white dark:tw-bg-gray-800">
      {items.map((item, index) => (
        <Link<any>
          {...item.url}
          key={index}
          className={`
              tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-1 tw-py-3 tw-no-underline
              ${
                item.active
                  ? 'tw-text-primary'
                  : 'tw-text-gray-600 dark:tw-text-gray-300'
              }
            `}
        >
          {!!item.icon &&
            item.icon({
              className: `tw-h-5 tw-w-5 tw-flex-shrink-0 ${
                item.active ? 'tw-text-primary-dark tw-fill-primary' : ''
              }`,
            })}
          <span className="tw-font-medium tw-text-xs tw-tracking-tight">
            {item.label}
          </span>
        </Link>
      ))}
    </nav>
  );
};

export default Tabs;
