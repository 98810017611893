import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const Check = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      d="m3 12 6.002 6L21 6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Check;
