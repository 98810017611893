import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const Grape = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      // fill="#cf1c44"
      d="M17.965 12.668h-.031c.3.5.5 1.098.5 1.73A3.428 3.428 0 0 1 15 17.832h-.035c.3.5.5 1.102.5 1.734C15.434 21.465 13.898 23 12 23a3.43 3.43 0 0 1-3.434-3.434c0-.632.2-1.234.5-1.734h-.03a3.426 3.426 0 0 1-3.434-3.434c0-.632.199-1.23.5-1.73h-.036a3.43 3.43 0 0 1-3.433-3.434 3.428 3.428 0 0 1 3.433-3.433c1.266 0 2.399.699 2.97 1.765.5-.867 1.331-1.5 2.331-1.699-.3-.867-.566-2.601.832-4.566.102-.168.336-.235.535-.168l2.434.902c.133.067.266.2.297.363a.428.428 0 0 1-.164.434c-.035 0-1.934 1.434-2.633 3.035a3.48 3.48 0 0 1 2.367 1.7C15.633 6.534 16.735 5.8 18 5.8a3.428 3.428 0 0 1 3.434 3.433c-.036 1.899-1.602 3.434-3.47 3.434Zm0 0"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={0.375}
      d="M21.398 9.266a3.428 3.428 0 0 0-3.433-3.434A3.381 3.381 0 0 0 15 7.602c-.5-.868-1.367-1.536-2.367-1.704.699-1.632 2.601-3.03 2.633-3.03a.44.44 0 0 0 .168-.434.48.48 0 0 0-.301-.368l-2.434-.898a.475.475 0 0 0-.531.164c-1.434 2-1.133 3.703-.836 4.566A3.5 3.5 0 0 0 9 7.602c-.602-1.036-1.7-1.77-2.965-1.77a3.428 3.428 0 0 0-3.433 3.434c0 1.902 1.53 3.402 3.433 3.402h.031c-.3.5-.5 1.098-.5 1.73A3.428 3.428 0 0 0 9 17.832h.035c-.3.5-.5 1.102-.5 1.734C8.566 21.465 10.102 23 12 23s3.434-1.535 3.434-3.434c0-.632-.2-1.234-.5-1.734h.03a3.426 3.426 0 0 0 3.434-3.434c0-.632-.199-1.23-.5-1.73h.036c1.933 0 3.464-1.535 3.464-3.402Zm-8.664-7.133 1.368.5C13.5 3.133 12.632 4 12.066 5c-.132-.7-.066-1.734.668-2.867ZM12 6.734a2.534 2.534 0 1 1-2.535 2.532A2.534 2.534 0 0 1 12 6.734Zm-5.965 5.067a2.532 2.532 0 1 1 0-5.067 2.53 2.53 0 0 1 2.531 2.532c0 1.367-1.132 2.535-2.53 2.535Zm3 5.133a2.532 2.532 0 1 1 0-5.067 2.53 2.53 0 0 1 2.531 2.531c0 1.403-1.168 2.536-2.53 2.536ZM12 22.102a2.534 2.534 0 1 1 0-5.066 2.534 2.534 0 1 1 0 5.066Zm2.965-5.168a2.531 2.531 0 0 1-2.531-2.536 2.53 2.53 0 0 1 2.53-2.53 2.532 2.532 0 1 1 0 5.066Zm.469-7.668a2.53 2.53 0 0 1 2.53-2.532 2.532 2.532 0 1 1 0 5.066c-1.398 0-2.53-1.167-2.53-2.534Zm0 0"
    />
  </SvgIcon>
);

export default Grape;
