import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const Location = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M4.239 10.391a7.678 7.678 0 0 1 15.357.052v.087c-.053 2.757-1.592 5.305-3.479 7.296a20.187 20.187 0 0 1-3.591 2.956.93.93 0 0 1-1.217 0 19.815 19.815 0 0 1-5.053-4.73 9.826 9.826 0 0 1-2.017-5.635v-.026Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={11.917}
      cy={10.539}
      r={2.461}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default Location;
