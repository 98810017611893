import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const Send = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m15.855 8.121-5.663 5.702L3.56 9.74c-.87-.534-.693-1.854.286-2.138l15.655-4.556c.89-.257 1.714.577 1.447 1.472l-4.645 15.64c-.291.978-1.596 1.147-2.123.274l-3.99-6.61"
    />
  </SvgIcon>
);

export default Send;
