import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const Card = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M6.888 3.5h9.428c1.36.015 2.653.59 3.58 1.59a5.017 5.017 0 0 1 1.326 3.704v6.528a5.017 5.017 0 0 1-1.326 3.704 4.957 4.957 0 0 1-3.58 1.59H6.888C3.968 20.616 2 18.241 2 15.322V8.794C2 5.875 3.968 3.5 6.888 3.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      stroke="currentColor"
      d="M2 9h19"
    />
  </SvgIcon>
);

export default Card;
