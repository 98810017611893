import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const Copy = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M20.959 7.174v6.608c0 2.448-1.532 4.175-3.98 4.175H9.964c-2.448 0-3.972-1.727-3.972-4.175V7.174C5.993 4.726 7.525 3 9.965 3h7.013c2.448 0 3.98 1.726 3.98 4.174Z"
      clipRule="evenodd"
    />
    <path
      fill="#FCFCFC"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.716 10.417v6.607c0 2.449-1.533 4.175-3.98 4.175H6.721c-2.448 0-3.972-1.726-3.972-4.175v-6.607c0-2.448 1.532-4.174 3.973-4.174h7.012c2.448 0 3.98 1.726 3.98 4.174Z"
      clipRule="evenodd"
    />
  </SvgIcon>
);

export default Copy;
