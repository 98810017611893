'use client';

import { FC } from 'react';
import { useSelectedLayoutSegments } from 'next/navigation';

export interface BreadcrumbItem {
  label: string;
  link?: {
    href: string;
    query?: { [key: string]: string };
  };
}

const Breadcrumb: FC = (props) => {
  const segments = useSelectedLayoutSegments();

  return (
    <ul>
      {segments.map((segment, index) => (
        <li key={index}>{segment}</li>
      ))}
    </ul>
  );
};

export default Breadcrumb;
