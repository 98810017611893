'use client';

import { useEffect, useState } from 'react';

export default function useWindowScroll() {
  if (typeof window === 'undefined') {
    return { isScrolledY: undefined, scrollY: undefined };
  }

  const [isScrolledY, setIsScrolledY] = useState(false);

  useEffect(() => {
    const handleScroll = () => setIsScrolledY(window.scrollY > 0);
    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { isScrolledY, scrollY: window.scrollY };
}
