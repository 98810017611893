import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const PayPal = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      d="M21.03 6.498c-.429-.509-1.005-.931-1.714-1.26a3.928 3.928 0 0 0-.657-1.107c-1.118-1.326-3.239-2.057-5.973-2.057-.819 0-4.055-.034-5.876-.074a1.559 1.559 0 0 0-1.551 1.215l-3.22 14.488c-.105.463.006.941.303 1.312.296.37.739.583 1.213.583h.96l-.106.472c-.104.463.007.941.304 1.312.296.37.739.583 1.214.583h3.898c.739 0 1.38-.526 1.525-1.25l.69-3.46 3.65-.041.03-.004c.242-.028 1.52-.214 2.89-1.14a7.163 7.163 0 0 0 1.97-2c.643-.974 1.084-2.137 1.31-3.458.292-1.706.003-3.09-.86-4.114ZM3.257 18.282a.377.377 0 0 1-.074-.324l3.22-14.487c.04-.177.2-.303.381-.298 1.828.04 5.079.074 5.902.074 2.348 0 4.198.598 5.076 1.64.63.747.832 1.81.6 3.16-.36 2.102-1.295 3.678-2.78 4.684-1.103.746-2.157.918-2.34.943l-3.626.042c-.553 0-.973.318-1.07.81l-.717 3.592a.383.383 0 0 1-.375.307H3.555a.376.376 0 0 1-.298-.143Zm17.477-7.868c-.36 2.102-1.296 3.678-2.782 4.684-1.101.746-2.156.918-2.34.944l-3.625.041c-.553 0-.973.318-1.07.81l-.717 3.592a.383.383 0 0 1-.375.307H5.927a.376.376 0 0 1-.299-.143.377.377 0 0 1-.074-.324l.162-.727h1.738a1.56 1.56 0 0 0 1.525-1.25l.69-3.46 3.65-.042.03-.003c.242-.028 1.52-.214 2.89-1.141a7.17 7.17 0 0 0 1.97-2c.643-.974 1.084-2.137 1.31-3.457.089-.52.123-1.01.105-1.47.194.148.364.307.509.479.63.747.832 1.81.6 3.16Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default PayPal;
