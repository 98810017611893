import { FC } from 'react';

interface Props {
  className?: string;
  type?: 'horizontal' | 'vertical';
}

const Divider: FC<Props> = (props = { type: 'vertical' }) => (
  <div
    className={`${
      props.type === 'horizontal' ? 'tw-w-px' : 'tw-h-px'
    } tw-bg-gray-200 ${props.className}`}
  />
);
export default Divider;
