import { FC, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface IProps {
  children: any;
  title?: string;
  onClose: () => void;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  whiteHeader?: boolean;
  isOpen: boolean;
}

const Modal: FC<IProps> = (props) => {
  const setModalSizeClasses = () => {
    switch (props.size) {
      case 'sm':
        return 'lg:tw-w-1/4 md:tw-w-2/5 tw-w-10/12';
      case 'md':
        return 'lg:tw-w-1/3 md:tw-w-3/5 tw-w-10/12';
      case 'xl':
        return 'lg:tw-w-3/4 tw-w-10/12';
      case 'lg':
      default:
        return 'lg:tw-w-1/2 md:tw-w-4/5 tw-w-10/12';
    }
  };

  const modalSize = setModalSizeClasses();

  const portal = (
    <Transition appear show={props.isOpen}>
      <Dialog as="div" className="tw-relative tw-z-[9200]" onClose={() => props.onClose()}>
        <div className="tw-fixed tw-inset-0 tw-z-[9200] tw-w-screen overflow-y-auto">
          <div className="tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4">
            <Transition.Child
              className={modalSize}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-scale-95"
              enterTo="tw-opacity-100 tw-scale-100"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-scale-100"
              leaveTo="tw-opacity-0 tw-scale-95"
            >
              <Dialog.Panel className={`tw-rounded-lg tw-bg-white tw-w-full`}>
                <div
                  className={
                    props.whiteHeader
                      ? 'tw-flex tw-items-center tw-justify-between tw-p-4 tw-border-b tw-border-[#e9ecef]'
                      : 'tw-flex tw-items-center tw-justify-between tw-p-4 tw-bg-primary'
                  }
                >
                  <Dialog.Title as="h4"
                                className={`tw-mb-0 tw-leading-normal tw-flex-grow ${props.whiteHeader ? 'tw-text-black' : 'tw-text-white'}`}>
                    {props.title}
                  </Dialog.Title>
                  <button
                    className="!tw-cursor-pointer tw-p-4 -tw-m-4 tw-flex tw-items-center"
                    type="button"
                    onClick={() => props.onClose()}
                  >
                    <i
                      className={
                        props.whiteHeader
                          ? 'tw-text-black icon-cross'
                          : 'tw-text-white icon-cross'
                      }
                    />
                  </button>
                </div>
                <div className="tw-p-4">
                  {props.children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        <div className="tw-bg-black tw-opacity-50 tw-z-[9100] tw-fixed tw-inset-0"></div>
      </Dialog>
    </Transition>
);

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted ? portal : null;
};

export default Modal;
