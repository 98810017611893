import { SVGProps } from 'react';
import SvgIcon from './SvgIcon';

const Menu = (props: SVGProps<SVGSVGElement>) => (
  <SvgIcon {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
    />
  </SvgIcon>
);

export default Menu;
